/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { ref } from '@vue/composition-api'
import axios from 'axios'
import { lsGetTokenInfo } from '@/plugins/lsstore/index'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'

// ** Disable fields
// const disableFields = () => {
// 	schemaAddress.groups[0].fields[2].disabled = true
// 	schemaAddress.groups[0].fields[3].disabled = true
// 	schemaAddress.groups[0].fields[5].disabled = true
// 	schemaAddress.groups[0].fields[6].disabled = true
// 	schemaAddress.groups[0].fields[7].disabled = true
// 	schemaAddress.groups[0].fields[10].disabled = true
// 	schemaAddress.groups[0].fields[11].disabled = true
// 	schemaAddress.groups[0].fields[12].disabled = true
// }

// ** Hide other fields
const hideOtherFields = ref(false)

// ** Cities
const citiesArray = ref([])
const citiesArrayMapped = ref([])
const GetCitiesQuery = `
	query GetCities {
  addresses_cities(order_by: {city_name: asc}) {
    city_name
		route_id_by_city
  }
}
`
// ** get headers
const getHeaders = () => {
	const headers = {}
	const token = lsGetTokenInfo()
	if (token) {
		headers.authorization = `Bearer ${token}`
	}
	return headers
}
const graphqlQuery = {
	query: GetCitiesQuery,
}
const endpoint = 'https://gtbs-crm-backend-app.herokuapp.com/v1/graphql'
const getDropdownData = () => {
	axios({
		url: endpoint,
		method: 'post',
		headers: getHeaders(),
		data: graphqlQuery,
	})
		.then((result) => {
			citiesArray.value = result?.data?.data?.addresses_cities
		})
		.then(() => {
			citiesArrayMapped.value = citiesArray.value.map((item) => item.city_name)
		})
		.then(() => citiesArrayMapped.value.unshift('Add New City!'))
		.then(() => {
			schemaAddress.groups[0].fields[7].values = citiesArrayMapped.value
		})
}
getDropdownData()
// ** ----------------------------------------------------------------------
// ** Get routes ids
const routesArray = ref([])
const routesArrayMapped = ref([])
const GetRoutesIdsQuery = `
	query GetRoutesIds {
  adresses_routes(order_by: {route_id: asc}) {
    route_id
    route_name
  }
}
`
const graphqlQueryRoutes = {
	query: GetRoutesIdsQuery,
}
axios({
	url: endpoint,
	method: 'post',
	headers: getHeaders(),
	data: graphqlQueryRoutes,
})
	.then((result) => {
		routesArray.value = result?.data?.data?.adresses_routes
	})
	.then(() => {
		routesArrayMapped.value = routesArray.value.map((item) => {
			return {
				id: item.route_id,
				name: item.route_id + ' - ' + item.route_name,
			}
		})
	})
// Define the form
const modelAddress = reactive({
	addressId: '',
	companyId: '', // Store.getters.getUserCurrentCompany,
	ownerId: '', // selectedOwner,
	addressNickname: '',
	addressType: '',
	routeId: '',
	newRouteId: '',
	line1: '',
	line2: '',
	city: '',
	newCityName: '',
	postCode: '',
	state: 'Skåne',
	country: 'Sverige',
	addressIsActive: true,
	listGroup: '',
	orderClerk: '',
	addressPhone: '',
	openHours: '',
})
const schemaAddress = reactive({
	groups: [
		{
			visible() {
				return !hideOtherFields.value
			},
			legend: 'Enter the address',
			id: 'addresses',
			featured: true,
			fields: [
				{
					// Only appear if packet value is true
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Our Company',
					model: 'companyId',
					id: 'our-company',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Address Owner Id',
					model: 'ownerId',
					id: 'owner-id',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Address Nickname',
					model: 'addressNickname',
					placeholder: 'Please enter address nickname',
					min: 6,
					max: 120,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in Address Nickname field! Errors:',
								errors,
							)
						else {
							console.log('addressNickname', modelAddress.addressNickname)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'select',
					label: 'Address Type',
					model: 'addressType',
					required: true,
					values: [
						'Invoice Address',
						'Dispatch Address',
						'Both Invoice & Dispatch Address',
					],
					default: 'Both Invoice & Dispatch Address',
					validator: ['string'],
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Route Id',
					model: 'routeId',
					readonly: true,
					featured: true,
					disabled: true,
					required: true,
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Address Line-1',
					model: 'line1',
					placeholder: 'Please enter address line-1',
					min: 6,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in Address Line-1 field! Errors:',
								errors,
							)
						else {
							console.log('line1', modelAddress.line1)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Address Line-2',
					model: 'line2',
					placeholder: 'Please enter address line-2',
					min: 6,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in Address Line-2 field! Errors:',
								errors,
							)
						else {
							console.log('line2', modelAddress.line2)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'select',
					label: 'City',
					model: 'city',
					placeholder: 'Please select city',
					min: 2,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					values: [],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in City field! Errors:', errors)
						else if (model.city === 'Add New City!') {
							model.newRouteId = ''
							model.newCityName = ''
							hideOtherFields.value = true
						} else {
							const findObjectById = (array, cityName) => {
								return citiesArray.value.find(
									(object) => object.city_name === cityName,
								)
							}
							model.routeId = findObjectById(
								citiesArray.value,
								model.city,
							).route_id_by_city
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.city === 'Add New City!'
					},
					type: 'input',
					inputType: 'text',
					label: 'New City Name',
					model: 'newCityName',
					placeholder: 'Please enter new city name',
					min: 2,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in new city name field! Errors:',
								errors,
							)
						else {
							console.log('line2', modelAddress.newCityName)
						}
					},
				},
				{
					// Only appear if packet value is true
					visible(model) {
						return model && model.city === 'Add New City!'
					},
					type: 'select',
					label: 'Route id',
					model: 'newRouteId',
					placeholder: 'Please select route',
					min: 2,
					max: 45,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					values: function () {
						return routesArrayMapped.value
					},
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in City field! Errors:', errors)
						// else {
						// 	const findObjectById = (array, cityName) => {
						// 		return citiesArray.value.find(
						// 			(object) => object.city_name === cityName,
						// 		)
						// 	}
						// 	model.routeId = findObjectById(
						// 		citiesArray.value,
						// 		model.city,
						// 	).route_id_by_city
						// }
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Post Code',
					model: 'postCode',
					placeholder: 'Please enter post code',
					min: 2,
					max: 6,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in Post Code field! Errors:',
								errors,
							)
						else {
							console.log('postCode', modelAddress.postCode)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'State',
					model: 'state',
					placeholder: 'Please enter state',
					min: 2,
					max: 25,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in State field! Errors:', errors)
						else {
							console.log('state', modelAddress.state)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Country',
					model: 'country',
					placeholder: 'Please enter country',
					min: 2,
					max: 25,
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in Country field! Errors:', errors)
						else {
							console.log('country', modelAddress.country)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Contact person for orders',
					model: 'orderClerk',
					placeholder: 'Please enter contact person for orders',
					min: 2,
					max: 25,
					readonly: false,
					featured: true,
					disabled: false,
					required: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in Country field! Errors:', errors)
						else {
							console.log('country', modelAddress.orderClerk)
						}
					},
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'input',
					inputType: 'text',
					label: 'Address Phone',
					model: 'addressPhone',
					placeholder: 'Please enter phone number',
					min: 7,
					max: 30,
					readonly: false,
					featured: true,
					disabled: false,
					required: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn('Validation error in Country field! Errors:', errors)
						else {
							console.log('country', modelAddress.adressPhone)
						}
					},
				},
				// todo -----------------------------------------------------
				{
					type: 'textArea',
					label: 'Open Hours',
					model: 'openHours',
					featured: true,
					hint: 'Max 300 characters',
					max: 300,
					placeholder: 'Open Hours',
					rows: 2,
					validator: ['string'],
				},
				// todo ---------------------------------------------------------
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'select',
					label: 'List Group',
					model: 'listGroup',
					featured: true,
					required: true,
					values: [
						'Call Every Week',
						'Call Monthly',
						'Visit Monthly',
						'Customer Calls Us',
						'Other Customers',
						'Only Invoice Address',
					],
					default: 'Call Every Week',
					validator: ['string'],
				},
				{
					visible() {
						return !hideOtherFields.value
					},
					type: 'checkbox',
					label: 'Active',
					model: 'addressIsActive',
					hint: 'Check the box if the address is active / uncheck the box if the address is inactive',
					featured: true,
					required: true,
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.warn(
								'Validation error in is Active field! Errors:',
								errors,
							)
						else {
							console.log('addressIsActive', modelAddress.addressIsActive)
						}
					},
				},
			],
		},
	],
})
console.log('schemaAddress', schemaAddress)
const formOptionsAddress = reactive({
	validateAfterLoad: false,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'address',
})
export default function useAddressFormState() {
	return {
		modelAddress,
		schemaAddress,
		formOptionsAddress,
		...toRefs(modelAddress),
		...toRefs(schemaAddress),
		...toRefs(formOptionsAddress),
		hideOtherFields,
		getDropdownData,
	}
}
